import React, { useState, useRef } from "react"
import MainPage from "../components/page-main"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const gatsbyData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          lang_configs {
            en {
              menu {
                prefix
                items {
                  label
                  path
                  external
                  no_pref
                }
              }
              dir
              plot_reminder
              trend {
                up
                down
                stable
              }
              instructions
              privacy_policy
              button_print
              button_delete
              link_logout
              link_edit_profile
              panel_title_instructions
              panel_title_recorder
              panel_title_plot
              panel_title_profile
              button_processing
              plot {
                legend
                tooltip
              }
              delete_dialog {
                button_delete
                button_cancel
                message
                disclaimer
              }
              recorder {
                button_recording
                button_idle
                button_processing
                button_yes
                button_no
                button_refuse
                button_same
                button_happy
                button_try_again
                button_download
                question_feeling_better
                remaining
                success_msg
              }
              profile {
                no_info
                button_save
                button_cancel
                button_processing
                button_send
                questionnaire_prompt
                sex
                male
                female
                other_sex
                age
                positive
                negative
                not_tested
                on_medication
                showing_symptoms
                symptoms {
                  field
                  label
                }
                medications {
                  field
                  label
                }
              }
            }
          }
        }
      }
    }
  `)
  return <MainPage lang_config={gatsbyData.site.siteMetadata.lang_configs.en} />
}
